<div id="container" [class.isHorizontalMode]="data.isHorizontalMode">
  <div
    id="image"
    [ngClass]="{ 'background-color': data.isBackgroundColor }"
    [ngStyle]="{ 'background-image': 'url(' + backgroundImage + ')' }"></div>
  <div id="info">
    <h2>{{ data.title | translate: locale.language }}</h2>
    <p>{{ data.text | translate: locale.language }}</p>

    @if (!noUrl) {
      <a [href]="this.data.buttonURL" class="button-href" target="_blank">
        <qr-button [config]="buttonAction"></qr-button>
      </a>
    } @else {
      <div class="button-href">
        <qr-button [config]="buttonAction"></qr-button>
      </div>
    }
  </div>
</div>
